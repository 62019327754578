import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "content-switcher",
      "style": {
        "position": "relative"
      }
    }}>{`Content Switcher`}</h1>
    <p>{`Content switcher allows the user to manipulate page content, switching between options, and by default one option is always selected. Use content switcher in pages with exclusive options, allowing the user to select one per time.`}</p>
    <h2 {...{
      "id": "best-practices",
      "style": {
        "position": "relative"
      }
    }}>{`Best practices`}</h2>
    <ul>
      <li parentName="ul">{`Consider using `}<a parentName="li" {...{
          "href": "/components/button"
        }}>{`Buttons`}</a>{` best practices to labels.`}</li>
      <li parentName="ul">{`By default, there's always an option selected.`}</li>
    </ul>
    <Demo src='pages/components/content-switch/ContentSwitch' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      